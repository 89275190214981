<template>
	<div>
        <v-layout wrap class="rc-collapseQuestions v-main-1100">
            <v-expansion-panel class="my-1" v-for="{ question, answers, id, i } in questionList" :key="i">
                <v-expansion-panel-content>
                    <template v-slot:header>
                        <div :id="id" class="body-2">{{ question }}</div>
                    </template>
                    <v-card class="rc-collapseCard">
                        <v-card-text class="grey lighten-3 body-1 px-3 justify" v-html="answers"></v-card-text>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-layout>
        <v-layout wrap v-if="questionList == 0" class="v-main-1100">
            <v-flex xs12 v-html="sinDatos"></v-flex>
        </v-layout>
    </div>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	
	@Component({
        props: {
			sinDatos : String,
            questionList : Array
        },
	})
	export default class CollapseQuestions extends Vue {}
</script>

<style lang="scss" scoped>
	.rc-collapseQuestions{
        .rc-collapseCard{
            border-radius: 0px;
        }
    }
</style>
